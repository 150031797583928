import { template as template_5e0074d8916b4aa9814c596590438518 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5e0074d8916b4aa9814c596590438518(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
